import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import AppThemeProvider from "./ThemeProvider"; // Import the configured Redux store

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Provider store={store}>
        {/* Wrap your app with BrowserRouter to enable routing */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </AppThemeProvider>
  </React.StrictMode>,
);
