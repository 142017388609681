import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { keyframes } from "@mui/system";

// Keyframes for spinner animation
const rotateSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Keyframes for text fade-in animation
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const phrases = [
  "Hang on a second...",
  "Just a second...",
  "We're almost there...",
  "Loading something awesome...",
  "Preparing your experience...",
];

const LoadingPage = () => {
  const [currentPhrase, setCurrentPhrase] = useState(
    phrases[Math.floor(Math.random() * phrases.length)],
  );

  useEffect(() => {
    // Function to pick a random phrase
    const pickRandomPhrase = () => {
      const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];
      setCurrentPhrase(randomPhrase);
    };

    // Set interval to pick a new phrase every 3 seconds
    const phraseInterval = setInterval(pickRandomPhrase, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(phraseInterval);
  }, []);

  return (
    <Container
      sx={{
        textAlign: "center",
        paddingTop: "100px",
        animation: `${fadeIn} 1s ease-in-out`,
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Box>
          <CircularProgress
            size={70}
            sx={{
              color: "#c49b40",
              thickness: 4,
              animation: `${rotateSpinner} 1.5s linear infinite`,
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              marginTop: "20px",
              color: "#444",
              animation: `${fadeIn} 1s ease-in-out`,
            }}
          >
            {currentPhrase}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default LoadingPage;
