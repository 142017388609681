import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./defaultBaseQuery";

interface EncouragementTexts {
  encouragementText: string;
}

interface BulletPointTexts {
  bulletPointsText: string;
}

interface GPTResponse {
  gptTextResponse: string;
}

export enum EmailTypes {
  PROFESSIONAL = "professional",
  FRIENDLY = "friendly",
  CONCISE = "concise",
}

export enum EmailRefinementTypes {
  REWRITE = "rewrite",
  FRIENDLIER = "friendlier",
  MORE_CONCISE = "more-concise",
  MORE_CONTEXT = "more-context",
}

export enum DocumentTypes {
  LETTER = "letter",
  REPORT = "report",
  ESSAY = "essay",
  PROPOSAL = "proposal",
}

export enum DocumentRefinementTypes {
  REWRITE = "rewrite",
  FORMAL = "report",
  INFORMAL = "informal",
  MORE_DETAIL = "more-detail",
  LESS_DETAIL = "less-detail",
}

export enum SummaryTypes {
  SUMMARY = "summary",
}

export enum SummaryRefinementTypes {
  BULLET_POINTS = "bullet-points",
  MORE_DETAIL = "more-detail",
  LESS_DETAIL = "less-detail",
  ONE_SENTENCE = "one-sentence",
  WORDS_OF_ENCOURAGEMENT = "words-of-encouragement",
}

const tags = ["encouragements"];
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: tags,
  endpoints: (builder) => ({
    processAudio: builder.mutation<{ transcribedText: string }, FormData>({
      query: (formData) => ({
        url: "/transcribe/process-audio",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: tags,
    }),
    getEncouragement: builder.mutation<
      EncouragementTexts,
      { text: string; detail: string }
    >({
      query: ({ text, detail }) => ({
        url: `/transcribe/encouragement/`,
        method: "POST",
        body: { text, detail },
      }),
      invalidatesTags: tags,
    }),
    getBulletPoints: builder.mutation<
      BulletPointTexts,
      { text: string; detail: string }
    >({
      query: ({ text, detail }) => ({
        url: `/transcribe/bullet-points/`,
        body: { text, detail },
        method: "POST",
      }),
      invalidatesTags: tags,
    }),
    getEmailPrompt: builder.mutation<
      GPTResponse,
      { text: string; type: EmailTypes }
    >({
      query: ({ text, type }) => ({
        url: `/emails/`,
        method: "POST",
        body: { type, text },
      }),
      invalidatesTags: tags,
    }),
    getEmailRefinementPrompt: builder.mutation<
      GPTResponse,
      { text: string; refinement: EmailRefinementTypes }
    >({
      query: ({ text, refinement }) => ({
        url: `/emails/refine`,
        method: "POST",
        body: { refinement, text },
      }),
      invalidatesTags: tags,
    }),
    getDocumentPrompt: builder.mutation<
      GPTResponse,
      { text: string; type: DocumentTypes }
    >({
      query: ({ text, type }) => ({
        url: `/documents`,
        method: "POST",
        body: { type, text },
      }),
      invalidatesTags: tags,
    }),
    getDocumentRefinementPrompt: builder.mutation<
      GPTResponse,
      { text: string; refinement: DocumentRefinementTypes }
    >({
      query: ({ text, refinement }) => ({
        url: `/documents/refine`,
        method: "POST",
        body: { refinement, text },
      }),
      invalidatesTags: tags,
    }),
    getSummaryPrompt: builder.mutation<
      GPTResponse,
      { text: string; type: SummaryTypes }
    >({
      query: ({ text, type }) => ({
        url: `/summary/`,
        method: "POST",
        body: { type, text },
      }),
      invalidatesTags: tags,
    }),
    getSummaryRefinementPrompt: builder.mutation<
      GPTResponse,
      { text: string; refinement: SummaryRefinementTypes }
    >({
      query: ({ text, refinement }) => ({
        url: `/summary/refine`,
        method: "POST",
        body: { refinement, text },
      }),
      invalidatesTags: tags,
    }),
  }),
});

// Export hooks generated by createApi for each endpoint
export const {
  useProcessAudioMutation,
  useGetEmailPromptMutation,
  useGetDocumentPromptMutation,
  useGetEmailRefinementPromptMutation,
  useGetDocumentRefinementPromptMutation,
  useGetSummaryPromptMutation,
  useGetSummaryRefinementPromptMutation,
} = apiSlice;
