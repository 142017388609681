import { createTheme } from "@mui/material/styles";

// Create a custom dark theme with specified colors and IconButton styling
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1B1E1E",
    },
    secondary: {
      main: "#282B2B",
    },
    background: {
      default: "#1B1E1E",
      paper: "#282B2B",
    },
    text: {
      primary: "#ffffff",
      secondary: "#B0B0B0",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#ffffff", // Default icon color for good contrast
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)", // Light hover effect for contrast
          },
          "&:focus": {
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly stronger focus effect
          },
        },
      },
    },
  },
});

export default darkTheme;
