import React from "react";
import "./App.css";
import { Box } from "@mui/material";
import AudioTranscriber from "./components/AudioTranscriber";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Box width={"100%"}>
      <ToastContainer />
      <AudioTranscriber />
    </Box>
  );
}

export default App;
