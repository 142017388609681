// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  Analytics,
  EventParams,
  logEvent,
} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9cdkw2SYjw7UhaH-yXzyeJEfi2T8BuWM",
  authDomain: "tranzcribe-333.firebaseapp.com",
  projectId: "tranzcribe-333",
  storageBucket: "tranzcribe-333.firebasestorage.app",
  messagingSenderId: "496862127059",
  appId: "1:496862127059:web:3eba5a095c43190d6e8526",
  measurementId: "G-75J9Q415CN",
};

interface BrowserInfo {
  browserName: string;
  browserVersion: string;
  platform: string;
}

export const getBrowserInfo = (): BrowserInfo => {
  const userAgentData = (navigator as any).userAgentData;
  let browserName = "Unknown";
  let browserVersion = "Unknown";
  let platform = "Unknown";

  if (userAgentData) {
    // Use userAgentData if available (for newer browsers)
    const brands = userAgentData.brands || userAgentData.uaList;
    browserName = brands[0]?.brand || "Unknown";
    browserVersion = brands[0]?.version || "Unknown";
    platform = userAgentData.platform || platform;
  } else {
    // Fallback to parsing navigator.userAgent for older browsers
    const userAgent = navigator.userAgent;
    platform = /Windows|Mac|Linux|Android|iOS/.exec(userAgent)?.[0] || platform;

    if (/firefox|fxios/i.test(userAgent)) {
      browserName = "Firefox";
      const match = /firefox\/(\d+)/i.exec(userAgent);
      browserVersion = match && match.length > 1 ? match[1] : browserVersion;
    } else if (/edg/i.test(userAgent)) {
      browserName = "Edge";
      const match = /edg\/(\d+)/i.exec(userAgent);
      browserVersion = match && match.length > 1 ? match[1] : browserVersion;
    } else if (/chrome|crios/i.test(userAgent)) {
      browserName = "Chrome";
      const match = /chrome\/(\d+)/i.exec(userAgent);
      browserVersion = match && match.length > 1 ? match[1] : browserVersion;
    } else if (/safari/i.test(userAgent)) {
      browserName = "Safari";
      const match = /version\/(\d+)/i.exec(userAgent);
      browserVersion = match && match.length > 1 ? match[1] : browserVersion;
    }
  }

  return {
    browserName,
    browserVersion,
    platform,
  };
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics: Analytics | null =
  typeof window !== "undefined" ? getAnalytics(app) : null;

export const logAnalyticsEvent = (
  eventName: string,
  eventParams?: EventParams,
) => {
  if (!analytics) {
    return;
  }
  console.log("Event logged");
  logEvent(analytics, eventName, { ...getBrowserInfo(), ...eventParams });
};
