import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

// Helper function to check if a value is an object with a specific key
function hasKey<K extends string>(
  obj: unknown,
  key: K,
): obj is { [key in K]: unknown } {
  return typeof obj === "object" && obj !== null && key in obj;
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | string | null | unknown,
): string {
  try {
    if (!error) return "An unknown error occurred.";

    // Handle string errors
    if (typeof error === "string") {
      return error;
    }

    // Handle standard Error objects
    if (error instanceof Error) {
      return error.message || "An unknown error occurred.";
    }

    // Handle error objects, including FetchBaseQueryError and other objects
    if (typeof error === "object" && error !== null) {
      // Check if it's a FetchBaseQueryError
      if (hasKey(error, "status")) {
        const fetchError = error as FetchBaseQueryError;

        // Extract specific error details from response data
        if (
          hasKey(fetchError, "data") &&
          typeof fetchError.data === "object" &&
          fetchError.data !== null
        ) {
          // Check if there's a primary message
          let primaryMessage = hasKey(fetchError.data, "message")
            ? String(fetchError.data.message)
            : "An error occurred.";

          // Handle nested error object safely
          if (
            hasKey(fetchError.data, "error") &&
            typeof fetchError.data.error === "object" &&
            fetchError.data.error !== null
          ) {
            const nestedError = fetchError.data.error as Record<string, any>;

            // Extract nested message, code, and type
            const nestedMessage = hasKey(nestedError, "message")
              ? String(nestedError.message)
              : "";
            const errorCode = hasKey(nestedError, "code")
              ? ` (Code: ${nestedError.code})`
              : "";
            const errorType = hasKey(nestedError, "type")
              ? ` Type: ${nestedError.type}`
              : "";

            // Append nested details to the primary message
            primaryMessage += ` - ${nestedMessage}${errorCode}${errorType}`;
          }

          return primaryMessage;
        }

        // Handle specific FetchBaseQueryError statuses
        switch (fetchError.status) {
          case "FETCH_ERROR":
            return "Network error: Please check your connection.";
          case "PARSING_ERROR":
            return "Failed to parse the response data.";
          case "TIMEOUT_ERROR":
            return "Request timed out. Please try again later.";
          case 400:
            return "Bad request. Please check the request and try again.";
          case 401:
            return "Unauthorized. Please log in and try again.";
          case 404:
            return "Resource not found. Please check the URL or resource.";
          case 413:
            return "Payload too large. Try reducing the size of the request.";
          case 500:
            return "Internal server error. Please try again later.";
          default:
            return `An unexpected error occurred (Status: ${fetchError.status}).`;
        }
      }

      // Handle SerializedError or other objects with a 'message' property
      if (hasKey(error, "message") && typeof error.message === "string") {
        return error.message;
      }

      // Check for errors with an 'error' property
      if (hasKey(error, "error") && typeof error.error === "string") {
        return error.error;
      }

      // Convert the object to a string if nothing else matches
      return JSON.stringify(error);
    }

    // Fallback for unknown error type
    return "An unknown error occurred.";
  } catch {
    // Catch any unexpected errors during the extraction process
    return "An unknown error occurred.";
  }
}
