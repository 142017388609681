import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  SummaryRefinementTypes,
  SummaryTypes,
  useGetSummaryPromptMutation,
  useGetSummaryRefinementPromptMutation,
} from "../store/apiSlice";
import { showErrorToast } from "../toastUtils";
import { getErrorMessage } from "../error-helpers/errorMessage";
import LoadingPage from "../common/LoadingPage";
import { logAnalyticsEvent } from "../config/firebase";

interface SummarizationTabProps {
  inputText: string;
  handleDownload: (text: string | null, filename: string) => void;
}

const SummarizationTab: React.FC<SummarizationTabProps> = ({
  inputText,
  handleDownload,
}) => {
  const [selectedPrimaryButton, setSelectedPrimaryButton] =
    useState<SummaryTypes | null>(SummaryTypes.SUMMARY);
  const [selectedSecondaryButton, setSelectedSecondaryButton] =
    useState<SummaryRefinementTypes | null>(null);
  const [text, setText] = useState<string>(inputText);
  const [refinedText, setRefinedText] = useState<null | string>(null);

  const [getSummaryPrompt, { isLoading: loadingSummary }] =
    useGetSummaryPromptMutation();
  const [getSummaryRefinementPrompt, { isLoading: loadingSummaryRefinement }] =
    useGetSummaryRefinementPromptMutation();

  const isLoading = loadingSummary || loadingSummaryRefinement;

  const fetchSummaryPrompt = async (summaryType: SummaryTypes) => {
    try {
      logAnalyticsEvent("fetchSummaryPrompt", {
        summaryType,
      });
      // use the transcribed text when the user clicks on the main prompt
      const response = await getSummaryPrompt({
        text: inputText,
        type: summaryType,
      }).unwrap();

      // set refined text to null
      setRefinedText(null);

      // set the gpt response text
      setText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const fetchSummaryRefinementPrompt = async (
    summaryRefinementType: SummaryRefinementTypes,
  ) => {
    try {
      logAnalyticsEvent("fetchSummaryRefinementPrompt", {
        summaryRefinementType,
      });
      // use 'text' which is generated from clicking the 'summary' button, which is based off the
      // transcribed text
      const response = await getSummaryRefinementPrompt({
        text,
        refinement: summaryRefinementType,
      }).unwrap();

      /// the refined text is sent instead of re-processing 'text' multiple times
      setRefinedText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const handleSummaryTypeClick = (summaryType: SummaryTypes) => {
    if (isLoading) {
      return;
    }

    setSelectedPrimaryButton(summaryType);
    setSelectedSecondaryButton(null);
    fetchSummaryPrompt(summaryType);
  };

  const handleSummaryRefinementClick = async (
    summaryRefinementType: SummaryRefinementTypes,
  ) => {
    if (isLoading) {
      return;
    }

    setSelectedSecondaryButton(summaryRefinementType);
    fetchSummaryRefinementPrompt(summaryRefinementType);
  };

  useEffect(() => {
    fetchSummaryPrompt(SummaryTypes.SUMMARY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Centering only the button groups */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          width: "100%",
          padding: "16px",
        }}
      >
        <ButtonGroup
          disabled={isLoading}
          variant="outlined"
          aria-label="primary button group"
          sx={{
            mb: 2,
            flexWrap: "wrap", // Allows buttons to wrap to the next line
            "& .MuiButton-root": {
              borderColor: "#c49b40",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px", // Adjust margin for wrapping
              "&:hover": {
                borderColor: "#c49b40",
                backgroundColor: "#c49c50",
                color: "#000000",
              },
              "&.Mui-disabled": {
                color: "#c49b40",
                opacity: 0.6,
              },
            },
            // Media query for small screens
            "@media (max-width: 600px)": {
              "& .MuiButton-root": {
                flex: "1 1 100%", // Stack buttons vertically on small screens
              },
            },
          }}
        >
          <Button
            onClick={() => handleSummaryTypeClick(SummaryTypes.SUMMARY)}
            disabled={isLoading}
            fullWidth={true}
            sx={{
              backgroundColor:
                selectedPrimaryButton === SummaryTypes.SUMMARY
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === SummaryTypes.SUMMARY
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Summary
          </Button>
        </ButtonGroup>

        {selectedPrimaryButton && (
          <ButtonGroup
            disabled={isLoading}
            variant="outlined"
            aria-label="secondary button group"
            sx={{
              mb: 2,
              flexWrap: "wrap", // Allows buttons to wrap to the next line
              "& .MuiButton-root": {
                borderColor: "#c49b40",
                color: "#FFFFFF",
                borderRadius: "24px",
                padding: "6px 16px",
                textTransform: "none",
                transition: "background-color 0.3s, border-color 0.3s",
                margin: "4px", // Adjust margin for wrapping
                "&:hover": {
                  borderColor: "#c49b40",
                  backgroundColor: "#c49b50",
                  color: "#FFFFFF",
                },
                "&.Mui-disabled": {
                  color: "#c49b40",
                  opacity: 0.6,
                },
              },
              // Media query for small screens
              "@media (max-width: 600px)": {
                "& .MuiButton-root": {
                  flex: "1 1 100%", // Stack buttons vertically on small screens
                },
              },
            }}
          >
            <Button
              onClick={() =>
                handleSummaryRefinementClick(
                  SummaryRefinementTypes.BULLET_POINTS,
                )
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.BULLET_POINTS
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.BULLET_POINTS
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Bullet Points
            </Button>
            <Button
              onClick={() =>
                handleSummaryRefinementClick(SummaryRefinementTypes.MORE_DETAIL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton === SummaryRefinementTypes.MORE_DETAIL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === SummaryRefinementTypes.MORE_DETAIL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              More Detailed
            </Button>
            <Button
              onClick={() =>
                handleSummaryRefinementClick(SummaryRefinementTypes.LESS_DETAIL)
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton === SummaryRefinementTypes.LESS_DETAIL
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === SummaryRefinementTypes.LESS_DETAIL
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Less Detailed
            </Button>
            <Button
              onClick={() =>
                handleSummaryRefinementClick(
                  SummaryRefinementTypes.ONE_SENTENCE,
                )
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.ONE_SENTENCE
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.ONE_SENTENCE
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              One Sentence
            </Button>
            <Button
              onClick={() =>
                handleSummaryRefinementClick(
                  SummaryRefinementTypes.WORDS_OF_ENCOURAGEMENT,
                )
              }
              disabled={isLoading}
              sx={{
                backgroundColor:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.WORDS_OF_ENCOURAGEMENT
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton ===
                  SummaryRefinementTypes.WORDS_OF_ENCOURAGEMENT
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Words of Encouragement
            </Button>
          </ButtonGroup>
        )}
      </Box>

      {/* Text content section remains separately formatted and aligned */}
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "18px" }}>
          Summarized Text
        </Typography>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <ReactMarkdown>{refinedText ?? text}</ReactMarkdown>
        )}

        <Button
          variant="contained"
          onClick={() => handleDownload(text, "summarization.pdf")}
          sx={{
            mt: 4,
            backgroundColor: "#c49b40",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#b28434",
            },
          }}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
};

export default SummarizationTab;
